import { Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import { Container, Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import DataLayerService from "utility/dataLayerService";
import LeafBox from "components/atoms/LeafBox";
import { useComponentName } from "hooks";

// Import SVG Icons.
import { LHHomeOwner, LHHouseHead, LHCareTaker, LHYes, LHNo } from "components/atoms/Icons";

import { FormContextLHSSMultiStepEstimate } from "./LHSSMultiStepEstimate";
import styled from "@emotion/styled";
import TermAndCondition from "components/atoms/TermAndCondition";
import { LeafButton } from "components/atoms/LeafButtons";
import ProgressBar from "components/atoms/ProgressBar";

const ProjectDetailsStep = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const { setActiveLHSSStepIndex, formData, setFormData } = useContext(FormContextLHSSMultiStepEstimate);
  const product = 'lhss';
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'LeafHomeZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);

  const handleRoleTypeClick = (value, setFieldValue) => {
    setFieldValue("roleType", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleFinancingClick = (value, setFieldValue) => {
    setFieldValue("financing", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: 552,
    width: "100%",
    height: "auto",
  }));


  return (
    <Formik
      initialValues={{
        homeOwner: "",
        financing: "",
      }}

      onSubmit={async (values) => {
        const data = { ...formData, ...values };
        setFormData(data);
        setActiveLHSSStepIndex('final');

        if (userJourneyTracking) {
          let stepNumber = 4;
          if ((componentName === 'LeafHomeZipChecker') || (componentName === 'ModalForm')) {
            stepNumber = 5;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 3, 'notcompleted');
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);

      }}
    >
      {({ handleSubmit, setFieldValue, isSubmitting }) => (
        <Container maxWidth={"sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form onSubmit={handleSubmit}>
              <h6 className="tracking-wide text-uppercase mb-4 fw-normal text-gray-600">Free Estimate</h6>
              <h3 className="fw-normal mb-3">
                Just a few more questions about your project.
              </h3>
              <LeafBox>
                <label className="font-medium text-green-600 small mb-3">What's your role?</label>
                  <Field name="roleType">
                    {() => (
                      <LeafBox style={{
                        display: "flex",
                        alignItems: "start"
                      }}>
                        <LHHomeOwner handleClick={() =>
                          handleRoleTypeClick("homeOwner", setFieldValue)
                        } />
                        <LHCareTaker handleClick={() =>
                          handleRoleTypeClick("careTaker", setFieldValue)
                        } />
                        <LHHouseHead handleClick={() =>
                          handleRoleTypeClick("familyMember", setFieldValue)
                        } />

                      </LeafBox>
                    )}
                  </Field>
              </LeafBox>

              <LeafBox>
                <label className="font-medium text-green-600 small mb-3">Are you interested in financing?</label>
                  <Field name="financing">
                    {() => (
                      <LeafBox style={{
                        display: "flex",
                        alignItems: "start"
                      }}>
                        <LHYes handleClick={() =>
                          handleFinancingClick("yes", setFieldValue)
                        } />
                        <LHNo handleClick={() =>
                          handleFinancingClick("no", setFieldValue)
                        } />
                      </LeafBox>
                    )}
                  </Field>
              </LeafBox>
              <Divider className="divider" />
              <LeafBox>
                <LeafButton
                  buttonClass='rounded-3 btn-primary w-100 fw-semibold'
                  type="submit"
                  isSubmitting={isSubmitting}
                >
                  Next
                </LeafButton>
              </LeafBox>
              <ProgressBar customClass="" />
              <TermAndCondition />
            </Form>
            </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default ProjectDetailsStep;
