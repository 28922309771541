import React, { useContext } from "react";
import { FormContext } from "../../../../App";
import { Container } from "@mui/material";
import LeafBox from 'components/atoms/LeafBox';

const ProgressBar = () => {
  const { activeStepIndex } = useContext(FormContext);

  const customClass1 = "success";
  const customClass2 = "incomplete"

  return (
    <Container disableGutters={true}>
      <LeafBox className="d-flex justify-content-center pt-3">
        <LeafBox className="progress" style={{ width: "50%" }}>
          <LeafBox
            className={`demo progress-bar mr-2 mb-0 ${
              activeStepIndex >= 0 ? customClass1 : customClass2
            }`}
            role="progressbar"
            style={{ width: "100%", backgroundColor: '#FF5524' }}
          ></LeafBox>
        </LeafBox>
        <LeafBox className="spacer"></LeafBox>
        <LeafBox className="progress" style={{ width: "50%" }}>
          <LeafBox
            className={`progress-bar mb-0 ${
              activeStepIndex >= 1 ? customClass1 : customClass2
            }`}
            role="progressbar"
            style={{ width: "100%", backgroundColor: '#FF5524' }}
          ></LeafBox>
        </LeafBox>
      </LeafBox>
    </Container>
  );
}

export default ProgressBar;
