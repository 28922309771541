import React, { useState } from "react";
import componentMap from "utility/utility";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { IconButton } from '@mui/material';
import Slide from '@mui/material/Slide';
import DataLayerService from "utility/dataLayerService";
import { LeafButton } from "components/atoms/LeafButtons";
import { useComponentName, useProduct, useVersionLeafhome } from "hooks";

export default function BasicModal(props) {
  const ModalComponent = componentMap(props.modalComponent);
  const title = props.dataSet?.title ? props.dataSet.title : "Open Modal";
  const location = props.dataSet?.location ? props.dataSet.location : "sticky-nav";
  const name = props.dataSet?.name ? props.dataSet.name : "button";
  const [open, setOpen] = useState(false);
  let body = document.getElementsByTagName('body')[0];
  const buttonType = props.dataSet?.buttonType ? `btn-${props.dataSet?.buttonType}` : 'btn-primary';
  let buttonClasses = [buttonType];
  const product = useProduct();
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'ZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const vLeafhome = useVersionLeafhome();

  // Hide the modal button for zipchecker form.
  if (props.extraClasses) {
    buttonClasses.push(...props.extraClasses);
  }

  const handleOpen = () => {
    setOpen(true);
    body.style.overflow = "hidden";

    if (userJourneyTracking) {
      let requiredFields = 1;
      if (componentName === 'ModalForm') {
        requiredFields = 0;
      }
      // Passing lf.form.status event to DataLayer.
      DataLayerService.formStatusEvent(formName, subformName, product, 1, requiredFields, 'notcompleted');
    }

    // Passing lf.cta.interaction event to DataLayer.
    DataLayerService.CTAInteractionEvent(name, location);

    // Passing the lf.estimatemodal.impression event to Datalayer.
    if (componentName !== 'ZipChecker' || componentName !== 'LeafHomeZipChecker') {
      DataLayerService.modalImpressionEvent();
    }
  };

  const handleClose = () => {
    setOpen(false);
    body.style.overflow = "auto";
    if (vLeafhome && props.zipCheckerModalId) {
      const zipInput = document.getElementById('leafHomeZipCodeInput');
      if (zipInput) {
        zipInput.focus();
      }
    }
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  return (
    <div>
      <LeafButton onClick={handleOpen} buttonClass={buttonClasses.join(" ")} id={props.zipCheckerModalId ? props.zipCheckerModalId: ''}>
        {title}
      </LeafButton>

      <Dialog open={open} className={open ? 'show-dialog' : 'hide-dialog z-n1'}
        onClose={handleClose}
        scroll="body"
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <DialogContent className='p-0'>
          <ModalComponent {...props}/>
        </DialogContent>
        <DialogActions>
          <IconButton autoFocus onClick={handleClose} className={`dialog-close p-0 fw-bold lh-1 position-absolute c-black text-black opacity-25 ${vLeafhome ? 'leafhome-close-icon' : ''}`}
            style={{
              top: '0',
              right: vLeafhome ? '20px' : '5px',
              fontSize: '20px',
              textShadow: '0 1px 0 #fff'
            }}
          >
            <span className={`${vLeafhome ? 'icon--close py-3' : ''}`}>
              {vLeafhome ? '' : '×'}
              </span>
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
