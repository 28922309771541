import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useRef } from "react";
import { FormContext } from "../../../../App";
import { Container, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import BasicModal from "../../Modal-Form/ModalForm";
import "./style.scss";
import "../global-style.scss";
import DataLayerService from "utility/dataLayerService";
import { useZipCodeValidation } from "utility/validation";
import PostService from "utility/postService";
import { useCountry, useProduct, useComponentName } from "hooks";
import { LeafButton } from "components/atoms/LeafButtons";
import TrustedFormScript from "components/atoms/TrustedFormScript";
import ValidationIcon from "components/atoms/ValidationIcon";
import debounce from "lodash/debounce";

const ZipChecker = (props) => {

  const zipCheckerComponentid = props.zipCheckerComponent;
  const zipSource = props.dataSet?.zipsource || "localzip";
  const zipCheckertitle = props.dataSet?.title ? props.dataSet.title : "Get A Free Estimate";
  const country = useCountry();
  const product = useProduct();
  let zipCode = '';
  let zipcodeInputValue = '';
  let serviceAreaFlag = false;
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'LeafHomeZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  let zipCodeValidationStatus = 'invalid';
  const zipCodeRef = useRef(null);

  // Zipcode Validation based on the Country Code.
  const zipCodeValidation = useZipCodeValidation();

  const { formData, setFormData, setVLeafhome } =
    useContext(FormContext);
  const leahomeVersion = props.dataSet?.vleafhome ? props.dataSet?.vleafhome : true;
  useEffect(() => {
    setVLeafhome(leahomeVersion);
  });
  // Validation for the Zipcode.
  const validate = async (values) => {
    if (!values.zipCode) {
      return {
        'zipCode': zipCodeValidation.zipCodeText + ' is required.'
      }
    }

    if (!zipCodeValidation.validationPattern.test(values.zipCode)) {
      return {
        zipCode: zipCodeValidation.validationMessage,
      }
    }

    if ((zipcodeInputValue !== values.zipCode) && (zipCodeValidation.validationPattern.test(values.zipCode))) {
      serviceAreaFlag = await PostService.checkAvailableService(values.zipCode, product, zipSource);
      zipcodeInputValue = values.zipCode;
      zipCodeValidationStatus = (serviceAreaFlag === true) ? 'valid' : 'invalid';
      if (userJourneyTracking) {
        DataLayerService.zipCodeEventTracking(formName, subformName, product, values.zipCode, 'completed', zipCodeValidationStatus);
      }
    }

    if (!serviceAreaFlag) {
      return {
        zipCode: zipCodeValidation.restrictedValidationMessage,
      }
    }
  };

  // Set the Zipcode value on Blur.
  const handleOnBlur = (event) => {
    zipCode = event.target.value;
  };

  const formSubmitHandler = async (values) => {
    const data = { ...formData, ...values };
    if ((zipCode) || (values.zipCode)) {
      setFormData(data);

      // Start of the Form.
      var lhStart = {
        'event': 'lf.form.start',
        'details': {
          'name': "Generic",
          'location': "hero",
          'type': "form",
          'action': "open",
          'form': "modal",
          'data': data,
        }
      };

      // Passing the lf.form.start event to Datalayer.
      DataLayerService.passDataToDataLayer(lhStart);

      const modalButton = document.getElementById("zip-checker-modal-id");
      modalButton.click();

      // Passing the lf.estimatemodal.impression event to Datalayer.
      DataLayerService.modalImpressionEvent();
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const onSubmit = debounce(formSubmitHandler, 100, {
    leading: true,
    trailing: false
  });

  return (
    <Formik
      initialValues={{
        zipCode: "",
      }}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, touched, errors, isSubmitting }) => (
        <div className="leafhome-forms leafhome-zipchecker-form mx-auto">
          <Container disableGutters maxWidth={isMobile ? "xs" : "sm"}>
            <Form
              onSubmit={handleSubmit}
              onBlur={
                (event) => { handleOnBlur(event) }
              }
            >
              <div className="position-relative">
                <div className="field-container">
                  <Field
                    innerRef={zipCodeRef}
                    className={`input-field mb-4 ${touched.zipCode && errors.zipCode
                        ? "error"
                        : touched.zipCode
                          ? "correct"
                          : ""
                      }`}
                    name="zipCode"
                    placeholder={"Enter Your Zip Code"}
                    id="leafHomeZipCodeInput"
                  />
                  <ValidationIcon fieldName={'zipCode'} touched={touched} errors={errors} />
                  <LeafButton
                    buttonClass='rounded-3 btn-primary btn-lg w-100'
                    type="submit"
                    isSubmitting = {isSubmitting}
                  >
                      {zipCheckertitle}
                  </LeafButton>
                  <BasicModal
                    modalComponent={zipCheckerComponentid}
                    country={country}
                    zipCheckerModalId="zip-checker-modal-id"
                    extraClasses={["d-none"]}
                    zipCode={formData.zipCode}
                    dataSet ={props.dataSet}
                  />
                </div>
              </div>
              <TrustedFormScript />
            </Form>
          </Container>
        </div>
      )}
    </Formik>
  );
}

export default ZipChecker;
