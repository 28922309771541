import { Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import { FormContextLHWSMultiStepEstimate } from "./LHWSMultiStepEstimate";
import * as yup from "yup";
import {
  Container,
  Paper,
  useTheme
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import DataLayerService from "utility/dataLayerService";
import Validation from "utility/validation";
import styled from "@emotion/styled";
import TextInput from "components/atoms/TextInput";
import EmailInput from "components/atoms/EmailInput";
import TermAndCondition from "components/atoms/TermAndCondition";
import { LeafButton } from "components/atoms/LeafButtons";
import { useVersion } from "hooks";
import ProgressBar from "components/atoms/ProgressBar";
import { useComponentName } from "hooks";
import ProgressBarWithPercentage from 'components/atoms/ProgressBarWithPercentage';
import { scrollToTop } from '../../../../../utility/functions';

const CustomerDetailsStep = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const discountText = props.dataSet?.discount ? props.dataSet.discount : "";
  const { setactiveLHWSStepIndex, formData, setFormData } = useContext(FormContextLHWSMultiStepEstimate);
  const marketoData = props.marketoData;
  const product = 'lhws';
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'LeafHomeZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const v2 = useVersion();
  const formRef = useRef(null);

  let firstName = '';
  let lastName = '';
  let emailAddress = '';

  const ValidationSchema = yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required('First Name is required.'),
    lastName: yup
      .string()
      .trim()
      .required('Last Name is required.'),
    emailAddress: yup
      .string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email Address is not valid.')
      .required("Email Address is required."),
  });


  const buttonSubmit = (e) => {
    const firstNameValidation = Validation.FirstNameDataLayerValidation(firstName);
    const lastNameValidation = Validation.FirstNameDataLayerValidation(lastName);
    const EmailValidation = Validation.EmailDataLayerValidation(emailAddress);

    // Validation Error.
    const validationError = {
      ...firstNameValidation,
      ...lastNameValidation,
      ...EmailValidation
    };

    if (Object.keys(validationError).length !== 0) {
      DataLayerService.formFailEvent(form, location, validationError);
    }

    if (v2) {
      scrollToTop(formRef);
    }
  }

  const handleOnBlur = (event) => {
    switch (event.target.name) {
      case 'emailAddress':
        emailAddress = event.target.value;
      break;

      case 'firstName':
        firstName = event.target.value;
      break;

      case 'lastName':
        lastName = event.target.value;
      break;

      default:
      break;
    }

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: isMobile ? "100%" : 520,
    width: "100%",
    height: isMobile ? "auto" : 720,
  }));

  let email = '';
  if (marketoData !== undefined && marketoData !== null) {
    firstName = marketoData.firstName?.trim() || '';
    lastName = marketoData.lastName?.trim() || '';
    email = marketoData.email ? marketoData.email : '';
  }

  return (
    <Formik
      initialValues={{
        firstName: firstName || '',
        lastName: lastName || '',
        emailAddress: email || '',
        termsOfService: false,
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        const { firstName, lastName, emailAddress } = values;
        const data = {
          ...formData,
          firstName: firstName,
          lastName: lastName,
          emailAddress: emailAddress,
        };
        setFormData(data);
        setactiveLHWSStepIndex('project');
        if (v2) {
          scrollToTop(formRef);
        }

        if (userJourneyTracking) {
          let stepNumber = 3;
          if ((componentName === 'LeafHomeZipChecker') || (componentName === 'ModalForm')) {
            stepNumber = 4;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 4, 'notcompleted');
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);
      }}
    >
      {({ handleSubmit, touched, errors, isSubmitting }) => (
        <Container  maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
          <StyledFormContainer>
              <Form
                className="flex flex-col justify-center items-start"
                onSubmit={handleSubmit}
                ref={formRef}
                onBlur={
                  (event) => {handleOnBlur(event)}
                }
              >
                <h6 className="tracking-wide text-uppercase mb-4 fw-normal text-gray-600">Free Estimate</h6>
                <h3 className="fw-normal mb-3">
                  Who should we contact about this estimate?
                </h3>

                <div className="flex flex-col items-start mb-2 label-bold">
                   <TextInput touched={touched} errors={errors} fieldName={'firstName'} label={'First Name'} placeholder={v2 ? "First Name" : "E.g., First Name"} />
                </div>
                <div className="flex flex-col items-start mb-2 label-bold">
                   <TextInput touched={touched} errors={errors} fieldName={'lastName'} label={'Last Name'} placeholder={v2 ? "Last Name" : "E.g., Last Name"} />
                </div>

                <div className="flex flex-col items-start mb-2 label-bold">
                  <EmailInput touched={touched} errors={errors} fieldName={'emailAddress'} />
                </div>
                { ((formData.groupLevel !== "" && formData.groupLevel !== 'neither'  ) || discountText ) ?
                  <>
                    <h6 className="fw-normal tracking-wide pt-3">DISCOUNT APPLIED:</h6>
                    <div className="discount-text">
                      <ul className="list-unstyled">
                        {discountText ? <li>
                          <h4 className="discount-text lead text-black"> { discountText } </h4>
                        </li> : ''
                        }
                        { formData.groupLevel !== "" && formData.groupLevel !== 'neither' ?
                        <li>
                          <h4 className="discount-text lead text-black">10% OFF: Senior/Military</h4>
                        </li>
                        : '' }
                      </ul>
                    </div>
                  </>
                  : ''}
                <Divider className="divider" />
                <LeafButton
                  buttonClass='rounded-3 btn-primary w-100 fw-semibold'
                  type="submit"
                  isSubmitting={isSubmitting}
                  handleClick={buttonSubmit}
                >
                  Next
                </LeafButton>
                { v2 ?
                  <ProgressBarWithPercentage />
                  :
                  <ProgressBar customClass="" />
                }
                <TermAndCondition />
              </Form>
            </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default CustomerDetailsStep;
