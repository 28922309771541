import { Field, ErrorMessage } from "formik";
import LeafBox from 'components/atoms/LeafBox';
import { useVersionLeafhome } from 'hooks';

const EstimateSourceSelect = () => {
  const vLeafhome = useVersionLeafhome();
  const inputStyle = {
    width: '100%',
    borderRadius: '3px',
    height: '40px',
    border: '1px solid #d1d1d1',
    padding: '5px 10px',
  };
  return (
    <LeafBox>
      <label htmlFor="source">
        How Did You Hear About Us?{" "}
        { !vLeafhome ? <span className="optional"> (optional) </span> : ''}
      </label>
      <Field
        component="select"
        name="source"
        id="source"
        style={inputStyle}
      >
        <option defaultValue="41">Choose</option>
        <option value="41">Online</option>
        <option value="81">Television</option>
        <option value="41TCTV">Streaming TV (Sling, TubiTV, Roku)</option>
        <option value="88w">Print Advertisement</option>
        <option value="10w">Postcard</option>
        <option value="91">Radio</option>
        <option value="99w">Event / Show</option>
        <option value="83">NASCAR</option>
        <option value="41PAV">YouTube</option>
        <option value="41P">Other</option>
      </Field>
      <ErrorMessage
        name="selectedOption"
        component="p"
        className="error-msgs"
      />
    </LeafBox>
  )
}

export default EstimateSourceSelect;
