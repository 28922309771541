import { Field, useFormikContext } from 'formik';
import React, { useContext } from "react";
import LeafBox from 'components/atoms/LeafBox';
import ValidationIcon from "components/atoms/ValidationIcon";
import { FormContext } from 'App';
import { useVersion, useVersionLeafhome } from "../../../hooks";

const EmailInput = ({fieldName, label, style = {}, boxStyle = {}}) => {
  const { errors, touched } = useFormikContext();
  const { brand } = useContext(FormContext);
  const v2 = useVersion();
  const vLeafhome = useVersionLeafhome();

  let emailPlaceHolder = 'E.g., john.smith@leaffilter.com';

  if (brand === 'lhss') {
    emailPlaceHolder = 'E.g., john.smith@leafhomesafetysolutions.com';
  }
  else if (brand === 'lhws') {
    emailPlaceHolder = v2 ? 'Email Address' : 'E.g., john.smith@leafhomewatersolutions.com';
  }

  if (vLeafhome) {
    emailPlaceHolder = 'E.g., john.smith@leafhome.com'
  }

  let inputStyle = vLeafhome ? {} : {
    width: '100%',
    borderRadius: '3px',
    height: '40px',
    border: '1px solid #d1d1d1',
    padding: '5px 10px',
  };

  inputStyle = {
    ...inputStyle,
    ...style
  }

  return (
    <LeafBox style={boxStyle}>
      <label>{ label  || 'Email Address' }
        {v2 ? (<span className="required--asterisk text-danger"> * </span>) : ''}
      </label>
      <Field
        name={fieldName}
        className={`input-field ${
          touched[fieldName] && errors[fieldName]
            ? "error"
            : touched[fieldName]
            ? "correct"
            : ""
        }`}
        placeholder={ emailPlaceHolder }
        style={inputStyle}
      />
      <ValidationIcon fieldName={fieldName} touched={touched} errors={errors} />
    </LeafBox>
  );
}

export default EmailInput;
