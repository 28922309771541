import { Field, useFormikContext } from 'formik';
import React from "react";
import InputMask from "react-input-mask";
import LeafBox from 'components/atoms/LeafBox';
import ValidationIcon from "../ValidationIcon";
import { useBrand, useVersion, useVersionLeafhome } from 'hooks';

const PhoneNumberInput = ({label, fieldName, phoneNumberText }) => {
  const { errors, touched } = useFormikContext();
  const brand = useBrand();
  const v2 = useVersion();
  const vLeafhome = useVersionLeafhome();
  let inputStyle = vLeafhome ? {} : {
    width: '100%',
    borderRadius: '3px',
    height: '40px',
    border: '1px solid #d1d1d1',
    padding: '5px 10px',
  };

  let maskFormat = '[0-9]';
  let maskText = '(999) 999-9999';
  if (brand === 'lhss') {
    maskFormat = '[2-9]';
    maskText = '(#99) 999-9999';
  }
  return (
    <LeafBox>
      <label>{ label || 'Phone Number' }
        {v2 ? (<span className="required--asterisk text-danger"> * </span>) : ''}
      </label>
      <Field name={fieldName}>
        {({ field }) => (
          <InputMask
            {...field}
            className={`input-field ${
              touched[fieldName] && errors[fieldName]
                ? "error"
                : touched[fieldName]
                ? "correct"
                : ""
            }`}
            mask={ maskText }
            placeholder={v2 ? "" : "E.g., 555-867-5309"}
            maskChar={ v2 ? "_" : ""}
            style={inputStyle}
            formatChars= {{
              '#': maskFormat,
              '9': '[0-9]',
            }}
          />
        )}
      </Field>
      <ValidationIcon fieldName={fieldName} touched={touched} errors={errors} />
      {  phoneNumberText !== undefined ? (
        <p className='mt-2'>{phoneNumberText}</p>
      ) : '' }
    </LeafBox>
  );
}

export default PhoneNumberInput;
