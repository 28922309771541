import React from "react";
import { Container, Paper, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/system";
import { LeafButton } from "components/atoms/LeafButtons";
import TermAndCondition from "components/atoms/TermAndCondition";
import AlertMessage from "components/atoms/AlertMessage";
import { useComponentName } from "hooks";
import DataLayerService from "utility/dataLayerService";
import LeafBox from "components/atoms/LeafBox";

// Import SVG Icons.
import { ReactComponent as PhoneIcon } from "components/Forms/form-icons/phone-icon.svg";
import { ReactComponent as CheckIcon } from "components/Forms/form-icons/tick-lhws.svg";

const FinalStep = (props) => {
  const phoneNumber = '1-888-701-5497';
  const telPhoneNumber = phoneNumber.replace('-', '');
  const product = 'lhws';
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'LeafHomeZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);

  const handleCallNow = () => {
    window.location.href = "tel:+" + telPhoneNumber;
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(({ theme }) => ({
    background: "#ffffff",
    margin: `${theme.spacing(6)} ${isMobile ? theme.spacing(2) : theme.spacing(6)}`,
    padding: `${theme.spacing(2)} ${isMobile ? theme.spacing(2) : theme.spacing(6)}`,
    borderRadius: 8,
    width: isMobile ? "100%": 520,
  }));

  if (userJourneyTracking) {
    let stepNumber = 1;
    if ((componentName === 'LeafHomeZipChecker') || (componentName === 'ModalForm')) {
      stepNumber = 2;
    }
    // Passing lf.form.status event to DataLayer.
    DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 5, 'completed');
  }

  const StyledDivider = styled(Divider)`
    margin: 16px auto;
    width: 90%;
    border: 2px solid #02a780;
    opacity: 1;
  `;

  const styles = {
    position: 'absolute',
    marginLeft: '-30px',
    width: '20px',
    height: '20px',
  };

  const iconStyles = {
    marginLeft: '32px',
  }

  return (
    <Container maxWidth={isMobile ? "xs" : "sm"}>
      <StyledFormContainer>
        <div className="thank-you">
          <LeafBox style={{width: 'calc(100% - 24px)'}}>
            <AlertMessage message={'Thank you! Your information has been received.'} severity={'success'} />
          </LeafBox>
          <h3 className="fw-bold">We'll be calling you soon to schedule your free water test and consultation.</h3>

          <p> Our water specialists will make recommendations for improving your water and provide you with a free, no-obligation quote. </p>

          <ul className="list-unstyled" style={iconStyles}>
            <li>
              <span className="bullet-icon ms-n1" style={styles}><CheckIcon/></span>
              <span> Our specialists will make recommendations for improving your water and provide you with a free, no-obligation quote. </span>
            </li>
            <li>
              <span className="bullet-icon ms-n1" style={styles}><CheckIcon/></span>
              <span> We are committed to you safety. <a href="https://www.leafhomewatersolutions.com/covid">Learn about our COVID-19 protocol.</a></span>
            </li>
          </ul>

          <StyledDivider />
          <p className="step5-sub-text text-center">
            Can't wait to schedule your free estimate? <br />
            Give us a call at{" "}
            <span className="phone-number" onClick={handleCallNow}>{`${phoneNumber}`}</span>
          </p>
          <LeafButton
            buttonClass='rounded-3 btn-primary w-100 fw-semibold flex'
            type="submit"
            handleClick={handleCallNow}
          >
            <PhoneIcon className="phone-icon" style={{ width: "18px" }}></PhoneIcon>
            <span style={{ marginLeft: "0.5rem" }}>Call Now</span>
          </LeafButton>
          <br />

          <TermAndCondition />
        </div>
      </StyledFormContainer>
    </Container>
  );
}
export default FinalStep;
